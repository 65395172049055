import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createStore } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './registerServiceWorker'

// Axios defaults
axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = 'https://api.gearhealthsystem.com/';

// Set authorization header
axios.interceptors.request.use(function (config) {
    const user = localStorage.getItem('user')
    
    if (user !== null) {
      config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('user')).token}`;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if(response.status === 401) {
        //add your code
        alert("You are not authorized");
    }
    return response;
}, (error) => {
    if (error.response && error.response.data) {
      //add your code
      return Promise.reject(error.response.data);
    }

    if(error.response.status === 401) {
        // add your code
        localStorage.removeItem('user')
    }   

    router.push({name: 'Login'})
    
    return Promise.reject(error.message);
});

// store instance setup
const store = createStore({
    state () {
      return {
        user: {},
        featured_stock: [],
        stocks: [],
        cart_stock: [],
        loading: true,
        notifications: [],
        total_notification: 0,
        notifications_length: 0,
        if_prescription: false,
        ad_stocks: [],
        learnData: []
      }
    },
    mutations: {
      //
      //Set learn data in state.
      setLearnData(state, data) {
        state.learnData = data;
      },
      setUser(state, user) {
        state.user = user;
      },
      setFeaturedStock(state, featured_stock) {
        state.featured_stock = featured_stock;
      },
      setStocks(state, stocks) {
        state.stocks = stocks;
      },
      setLoading(state, loading) {
        state.loading = loading
      }, 
      setCartStock(state, cart_stock) {
        state.cart_stock = cart_stock
      },  
      setNotifications(state, notifications) {
        state.notifications = notifications
      },
      setTotalNotification(state, total) {
        state.total_notification = total
      },
      setNotificationsLength(state, length) {
        state.notifications_length = length
      },
      setIfPrescription(state, value) {
        state.if_prescription = value
      },
      setAdStocks(state, value) {
        state.ad_stocks = value
      }
    },
    actions: {
      async getUser(context) {
        context.commit("setLoading", true)
        
        axios.post('api/user').then((response) => {
          context.commit("setUser", response.data.data);
          context.commit("setLoading", false)
        }).catch(() => {
          context.commit("setUser", {});
          context.commit("setLoading", false)
        })
      },
      async getDriver(context) {
        context.commit("setLoading", true)
        axios.post('api/user').then((response) => {
          context.commit("setUser", response.data.data);
        }).catch(() => {
          context.commit("setUser", {});
        })
      },      
      async getFeaturedStock(context) {
        axios.get('api/featured-stocks').then((response) => {
          context.commit("setFeaturedStock", response.data.data);
        }).catch(() => {
          context.commit("setFeaturedStock", []);
        })
      },
      async getAllStocks(context) {
        axios.get('api/stocks').then((response) => {
          context.commit("setStocks", response.data.data);
        }).catch(() => {
          context.commit("setStocks", []);
        })
      },
      async getStockInCart(context) {
        axios.get('api/current-cart-items').then((response) => {
          context.commit("setCartStock", response.data.data);
          context.commit("setIfPrescription", response.data.if_prescription);
        }).catch(() => {
          context.commit("setCartStock", []);
        })
      },
      async cartItemsByDelivery(context, id) {
        axios.get('api/delivery-cart-items/' + id).then((response) => {
          context.commit("setCartStock", response.data.data);
        }).catch(() => {
          context.commit("setCartStock", []);
        })
      },
      async changeLoading(context, value) {
        context.commit("setLoading", value)
      },
      async getNotifications(context, value) {
        axios.get('api/notifications-by-user?page=' + value + '&per_page=2').then((response) => {
          context.commit("setNotifications", response.data.data.data);
          context.commit("setTotalNotification", response.data.data.total)
          context.commit("setNotificationsLength", response.data.data.data.length)
        }).catch(() => {
          context.commit("setNotifications", []);
        })
      },
      async getAdStock(context) {
        axios.get('api/stocks?featured_ad=true').then((response) => {
          context.commit("setAdStocks", response.data.data);
        }).catch(() => {
          context.commit("setAdStocks", []);
        })
      }
    },
    getters: {
      user: state => state.user,
      featured_stock: state => state.featured_stock,
      stocks: state => state.stocks,
      cart_stock: state => state.cart_stock,
      notifications: state => state.notifications,
      total_notification: state => state.total_notification,
      notifications_length: state => state.notifications_length,
      loading: state => state.loading,
      if_prescription: state => state.if_prescription,
      ad_stocks: state => state.ad_stocks
    }
})

createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
