import { createRouter, createWebHistory } from 'vue-router'
import nProgress from 'nprogress'
import '../../node_modules/nprogress/nprogress.css';

function view (path) {
  //
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  return () => import(/* webpackChunkName: '' */ `../views/${path}`).then(m => m.default || m)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: view('Home.vue'),
    props: true
  },
  {
    path: '/learn',
    name: 'Learn',
    component: view('Learn.vue')
  },
  {
    path: '/learnBookmarks',
    name: 'LearnBookmarks',
    component: view('LearnBookmarks.vue')
  },
  {
    path: '/learnDetails/:id',
    name: 'LearnDetails',
    component: view('LearnDetails.vue'),
    props: true
  },
  {
    path: '/learnWellnessDetails/:id',
    name: 'LearnWellnessDetails',
    component: view('LearnWellnessDetails.vue'),
    props: true
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: view('admin/Users.vue')
  },
  {
    path: '/admin/suppliers',
    name: 'AdminSuppliers',
    component: view('admin/Suppliers.vue')
  },
  {
    path: '/admin/orders',
    name: 'AdminOrders',
    component: view('admin/Orders.vue')
  },
  {
    path: '/admin/stock',
    name: 'AdminStock',
    component: view('admin/Stock.vue')
  },  
  {
    path: '/admin/delivery',
    name: 'AdminDelivery',
    component: view('admin/Delivery.vue')
  },  
  {
    path: '/login',
    name: 'Login',
    component: view('auth/Login.vue')
  },
  {
    path: '/driver/register',
    name: 'Driver.Register',
    component: view('driver-auth/Register.vue')
  },
  {
    path: '/doctor/register',
    name: 'Doctor.Register',
    component: view('auth/doctor/Register.vue')
  },  
  {
    path: '/register',
    name: 'Register',
    component: view('auth/Register.vue')
  },
  {
    path: '/ailment-checkup',
    name: 'AilnessCheckup',
    component: view('auth/AilnessCheckup.vue'),
    props: true
  },
    
  {
    path: '/suppliers/register',
    name: 'Supplier.Register',
    component: view('supplier-auth/Register.vue')
  },
  {
    path: '/suppliers/stocks',
    name: 'SupplierStocks',
    component: view('suppliers/Stocks.vue')
  },
  {
    path: '/suppliers/orders',
    name: 'SupplierOrders',
    component: view('suppliers/Orders.vue')
  },
  {
    path: '/suppliers/users',
    name: 'SupplierUsers',
    component: view('suppliers/Users.vue')
  }, 
  {
    path: '/suppliers/user-register/:email/:supplier_id',
    name: 'SupplierUserRegister',
    component: view('supplier-auth/UserRegister.vue'),
    props: true
  }, 
  {
    path: '/kyc',
    name: 'Kyc',
    component: view('auth/Kyc.vue'),
    props: true
  },  
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: view('auth/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token/:email',
    name: 'ResetPassword',
    component: view('auth/ResetPassword.vue'),
    props: true
  },
  {
    path: '/otp',
    name: 'Otp',
    component: view('auth/Otp.vue'),
    props: true
  },
  {
    path: '/profile',
    name: 'Profile',
    component: view('Profile.vue')
  },  
  {
    path: '/delete-user',
    name: 'DeleteUser',
    component: view('DeleteUser.vue')
  },  
  {
    path: '/email-verified',
    name: 'EmailVerified',
    component: view('auth/EmailVerified.vue')
  }, 
  {
    path: '/delivery',
    name: 'Delivery',
    component: view('Delivery.vue')
  },
  {
    path: '/delivery-details',
    name: 'DeliveryDetails',
    component: view('DeliveryDetails.vue'),
    props: true
  },  
  {
    path: '/saved',
    name: 'SavedProducts',
    component: view('SavedProducts.vue')
  },  
  {
    path: '/my-deliveries',
    name: 'MyDeliveries',
    component: view('MyDeliveries.vue')
  },  
  {
    path: '/cart',
    name: 'Cart',
    component: view('Cart.vue')
  },     
  {
    path: '/notifications',
    name: 'Notifications',
    component: view('Notifications.vue')
  },    

  {
    path: '/prescription',
    name: 'Prescription',
    component: view('Prescription.vue'),
    props: true
  },
  {
    path: '/doctor/prescription',
    name: ' ',
    component: view('doctor/Prescription.vue'),
    props: true
  },  
  {
    path: '/cancel-delivery',
    name: 'CancelDelivery',
    component: view('CancelDelivery.vue'),
    props: true
  },
  {
    path: '/payment-instructions',
    name: 'PaymentInstructions',
    component: view('PaymentInstructions.vue'),
    props: true
  },  
  {
    path: '/not-verified',
    name: 'NotVerified',
    component: view('NotVerified.vue')
  }, 
  {
    path: '/driver/home',
    name: 'DriverHome',
    component: view('driver/DriverHome.vue')
  },
  {
    path: '/driver/delivery',
    name: 'DriverDelivery',
    component: view('driver/DriverDelivery.vue')
  },   
  {
    path: '/delivery-status',
    name: 'DeliveryStatus',
    component: view('DeliveryStatus.vue'),
    props: true
  },  
  {
    path: '/doctor/requests',
    name: 'RequestedPrescriptions',
    component: view('doctor/RequestedPrescriptions.vue'),
    props: true
  }, 
  {
    path: '/doctor/my-requests',
    name: 'DoctorRequests',
    component: view('doctor/DoctorRequests.vue'),
    props: true
  },   
  {
    path: '/requested-drugs',
    name: 'RequestedDrugs',
    component: view('RequestedDrugs.vue'),
    props: true
  }, 
  {
    path: '/cart-to-doctor',
    name: 'CartToDoctor',
    component: view('CartToDoctor.vue'),
    props: true
  },
  {
    path: '/doctor/prescriptions',
    name: 'DoctorPrescription',
    component: view('doctor/Prescription.vue'),
    props: true
  },  
  {
    path: '/choose-prescription',
    name: 'ChoosePrescription',
    component: view('ChoosePrescription.vue')
  },    
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  if (to.name) {
    // Start the route progress bar.
    nProgress.start()
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  nProgress.done()
})


export default router
