<template>
    <router-view></router-view>
</template>

<style lang="scss">
    @import './assets/scss/base/_base.scss';
    @import './assets/scss/base/_fonts.scss';
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'

import { faTelegramPlane, faGalacticRepublic, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

import { faBell, faQuestion, faCogs, faAngleRight, faAngleLeft, faBars, faEllipsisH, faCheckCircle, faTimesCircle, faTimes, faStar, faSearch, faEnvelope, faDollarSign, faHeart, faCog, faHome, faPlus, faHouseUser, faPowerOff, faUsers, faSignOutAlt, faUser, faFile, faAngleDown, faPlusCircle, faPrint, faCrown, faHammer, faChartPie, faExclamationTriangle, faLightbulb, faLock, faUnlock, faEye, faInfoCircle, faArrowLeft, faMoon, faBoxes, faProjectDiagram, faPhoneAlt, faTruckLoading, faIdCard, faVenusMars, faClock, faRing, faUserEdit, faLocationArrow, faSpinner, faCircle, faHandsHelping, faTruck, faSyringe, faTablets, faCheck, faBuilding, faEyeSlash, faTablet, faPrescriptionBottle, faStethoscope, faFileMedicalAlt, faTrash, faCartPlus, faCircleNotch, faRoad, faBookmark, faMinus, faMoneyBillWave, faBan, faMotorcycle, faCar, faStepBackward, faCaretLeft, faCaretRight, faUserShield, faTruckMoving, faShieldVirus, faPrescription } from '@fortawesome/free-solid-svg-icons'

library.add(faUser, faHeart, faQuestion, faTelegramPlane, faPrint, faBars, faAngleLeft, faTimesCircle, faAngleRight, faCheckCircle, faTimes, faStar, faSearch, faGalacticRepublic, faDollarSign, faCog, faEnvelope, faPlus, faHome, faBell, faPowerOff, faUsers, faEllipsisH, faFile, faHouseUser, faCogs, faSignOutAlt, faAngleDown, faPlusCircle, faCrown, faHammer, faChartPie, faExclamationTriangle, faLightbulb, faLock, faUnlock, faEye, faFacebook, faTwitter, faInfoCircle, faArrowLeft, faMoon, faBoxes, faProjectDiagram, faPhoneAlt, faTruckLoading, faIdCard, faVenusMars, faClock, faRing, faUserEdit, faLocationArrow, faSpinner, faUsers, faCircle, faHandsHelping, faBoxes, faTruck, faSyringe, faTablets, faCheck, faBuilding, faEyeSlash, faTablet, faPrescriptionBottle, faStethoscope, faFileMedicalAlt, faTrash, faCartPlus, faCircleNotch, faRoad, faFile, faBookmark, faMinus, faMoneyBillWave, faBan, faFileMedicalAlt, faMotorcycle, faCar, faStepBackward, faCaretLeft, faCaretRight, faUserShield, faTruckMoving, faShieldVirus, faPrescription)

export default {
    name: "App",
    methods: {
        close() {
            document.querySelector('#sidebar-wrapper').classList.remove('toggled');
            document.querySelector('.backdrop').classList.remove('on');
        }
    }    
}

</script>